ruby {
    ruby-align: center;
}

rt {
    font-size: 0.6em;
    text-align: center;
    line-height: 1.5;
}

rb {
    display: inline-block;
    text-align: center;
}